import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import 'bootstrap';
// import {
//   BootstrapVue
// } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false;
// Vue.use(BootstrapVue);
import vuetimeline from "@growthbunker/vuetimeline"

Vue.use(vuetimeline)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');