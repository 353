<template>
  <div id="about" class="main_content">
    <div class="main_text">
      <div> Hi,</div>
      <div>
        I'm
        <div class="my_name">
          Khalil
        </div>,
      </div>
      <div>Web Developer</div>
    </div>
    <div class="secondary_text mt-4">
      I Build, Design, Fix, Debug & Test Web Apps
    </div>
    <a id="contact_button" variant="outline-warning w100" class="btn btn-outline-warning " href="#projects">Check out my
      work !
    </a>
    <div class="scroll-down">
      <span>scroll down</span>
      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="arrow-down" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-down fa-w-14 fa-5x">
        <path fill="currentColor"
          d="M443.5 248.5l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L241 419.9V44c0-6.6-5.4-12-12-12h-10c-6.6 0-12 5.4-12 12v375.9L28.5 241.4c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.8 4.8-12.3.1-17z"
          class=""></path>
      </svg>
    </div>
    <div class="scroll-down scroll-down--left">
      <span>scroll down</span>
      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="arrow-down" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-down fa-w-14 fa-5x">
        <path fill="currentColor"
          d="M443.5 248.5l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L241 419.9V44c0-6.6-5.4-12-12-12h-10c-6.6 0-12 5.4-12 12v375.9L28.5 241.4c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.8 4.8-12.3.1-17z"
          class=""></path>
      </svg>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Landing',
    props: {},
    data() {
      return {

      };
    },
    methods: {}
  };
</script>
<style>
  .scroll-down {
    position: absolute;
    right: 45px;
    bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }

  .scroll-down span {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    -webkit-animation-name: bounce-1;
    animation-name: bounce-1;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .scroll-down svg {
    margin-top: 10px;
    width: 10px;
  }

  .scroll-down--left {
    left: 45px;
    right: auto;
  }




  @-webkit-keyframes bounce-1 {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }

    50% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px)
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

  @keyframes bounce-1 {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }

    50% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px)
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }
</style>