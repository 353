<template>
    <div id="contact" class="main_content mb-5 pb-5">
        <div class="col-lg-6">
            <div class="main_text">
                <div>Contact me</div>
            </div>
            <div class="story mt-5">
                I’m interested in pursuing freelance opportunities—especially large or ambitious projects. If you
                have any other requests or questions, please don’t hesitate to use the form below.
            </div>
            <form class="my-form mt-5" @submit="sendEmail">
                <ul>
                    <li>
                        <div class="grid grid-2">
                            <input type="text" placeholder="Name" v-model="name" required>
                            <input type="text" placeholder="Surname" v-model="surname">
                        </div>
                    </li>
                    <li>
                        <div class="grid grid-2">
                            <input type="email" placeholder="Email" v-model="email"  required>
                            <input type="tel" placeholder="Phone" v-model="phone">
                        </div>
                    </li>
                    <li>
                        <textarea placeholder="Message" v-model="message" required></textarea>
                    </li>
                    <li>
                        <div class="grid grid-3">
                            <!-- 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI for debugging -->
                            <vue-recaptcha :theme="'dark'" @verify="verifyMethod" :loadRecaptchaScript="true"
                                ref="recaptcha"
                                sitekey="6LeXKWUgAAAAAOhVaDAvpqHP3DIja2LhmRA6CUGV">
                            </vue-recaptcha>
                        </div>
                    </li>
                    <li>
                        <div class="grid grid-3">
                            <button class="btn-grid" type="submit" >
                                <span class="back">
                                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg" alt="">
                                </span>
                                <span class="front">SUBMIT</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="alert alert-success" role="alert" v-if="formStatus=='success'">
                            Email sent, I'll get back to you shortly.
                        </div>
                        <div class="alert alert-danger" role="alert" v-if="formStatus=='error'">
                            An error happened, please try to contact me directy by email at <a href="mailto:hebachikhalil@gmail.com">hebachikhalil@gmail.com</a>
                        </div>
                        <div class="alert alert-danger" role="alert" v-if="formStatus=='recaptcha'">
                           The recaptcha is required. or you can send me an email at <a href="mailto:hebachikhalil@gmail.com">hebachikhalil@gmail.com</a>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>
</template>
<script>
    import emailjs from 'emailjs-com';
    import { VueRecaptcha } from 'vue-recaptcha';
    export default {
        name: 'Contact',
        props: {},
        components: { VueRecaptcha },
        data() {
            return {
                recaptchaResponse:"",
                formStatus:"",
                name:"",                    
                surname:"",
                email:"",
                phone:"",
                message:"",
                
            };
        },
        methods: {
            resetRecaptcha(){
                         setTimeout(function(){
                            that.formStatus="";
                            that.recaptchaResponse="";
                            that.$refs.recaptcha.reset()
                        },3000)
            },
            verifyMethod(e){
                this.recaptchaResponse=e
            },
            sendEmail(e) {
                e.preventDefault();
                let that =this;
                if(this.recaptchaResponse!=""){
                    try {
                        let template_params={
                            "g-recaptcha-response":this.recaptchaResponse,
                            "to_name": "0xdevkh | "+this.name,
                            "from_name": "0xdevkh | "+this.name,
                            "message":"name: "+this.name+"email: "+this.email+" phone: "+this.phone+" surname: "+this.surname+" message: "+this.message
                        }
                        emailjs.send('service_wevx2za', 'template_hw2496n',template_params,
                        'U2rRJ5jy-TLppGrQ0', ) .then(function(response) {
                            that.formStatus="success";
                            that.recaptchaResponse="";
                            that.name = ''
                            that.email = ''
                            that.surname = ''
                            that.message = ''
                            that.phone = ''
                            that.resetRecaptcha();
                        }, function(error) {
                            that.formStatus="error";
                            that.resetRecaptcha();
                        });
    
                    } catch(error) {
                       that.formStatus="error";
                       that.resetRecaptcha();
                    }
                }else{
                    that.formStatus="recaptcha";
                    that.resetRecaptcha();
                }
                },
        }
    };
</script>
<style scoped>
    input,
    select,
    textarea,
    button {
        font-family: inherit;
        font-size: 100%;
    }

    button,
    label {
        cursor: pointer;
    }

    select {
        appearance: none;
    }

    /* Remove native arrow on IE */
    select::-ms-expand {
        display: none;
    }

    /*Remove dotted outline from selected option on Firefox*/
    /*https://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff/18853002#18853002*/
    /*We use !important to override the color set for the select on line 99*/
    select:-moz-focusring {
        color: transparent !important;
        text-shadow: 0 0 0 var(--white);
    }

    textarea {
        resize: none;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    body {
        font: 18px/1.5 "Open Sans", sans-serif;
        background: #292a2b;
        color: var(--white);
        margin: 1.5rem 0;
    }

    .container {
        max-width: 800px;
        margin: 0 auto;
        padding: 0 1.5rem;
    }


    /* FORM ELEMENTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
    .my-form h1 {
        margin-bottom: 1.5rem;
    }

    .my-form li,
    .my-form .grid>*:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    .my-form select,
    .my-form input,
    .my-form textarea,
    .my-form button {
        width: 100%;
        line-height: 1.5;
        padding: 15px 10px;
        border: 1px solid var(--borderFormEls);
        color: var(--white);
        background: var(--bgFormEls);
        transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
            transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
    }

    .my-form textarea {
        height: 170px;
    }

    .my-form ::placeholder {
        color: inherit;
        /*Fix opacity issue on Firefox*/
        opacity: 1;
    }

    .my-form select:focus,
    .my-form input:focus,
    .my-form textarea:focus,
    .my-form button:enabled:hover,
    .my-form button:focus,
    .my-form input[type="checkbox"]:focus+label {
        background: var(--bgFormElsFocus);
    }

    .my-form select:focus,
    .my-form input:focus,
    .my-form textarea:focus {
        transform: scale(1.02);
    }

    .my-form *:required,
    .my-form select {
        background-repeat: no-repeat;
        background-position: center right 12px;
        background-size: 15px 15px;
    }

    .my-form *:required {
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg);
    }

    .my-form select {
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/down.svg);
    }

    .my-form *:disabled {
        cursor: default;
        filter: blur(2px);
    }


    /* FORM BTNS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
    .my-form .required-msg {
        display: none;
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg) no-repeat center left / 15px 15px;
        padding-left: 20px;
    }

    .my-form .btn-grid {
        position: relative;
        overflow: hidden;
        transition: filter 0.2s;
    }

    .my-form button {
        font-weight: bold;
    }

    .my-form button>* {
        display: inline-block;
        width: 100%;
        transition: transform 0.4s ease-in-out;
    }

    .my-form button .back {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-110%, -50%);
    }

    .my-form button:enabled:hover .back,
    .my-form button:focus .back {
        transform: translate(-50%, -50%);
    }

    .my-form button:enabled:hover .front,
    .my-form button:focus .front {
        transform: translateX(110%);
    }


    /* CUSTOM CHECKBOX
–––––––––––––––––––––––––––––––––––––––––––––––––– */
    .my-form input[type="checkbox"] {
        position: absolute;
        left: -9999px;
    }

    .my-form input[type="checkbox"]+label {
        position: relative;
        display: inline-block;
        padding-left: 2rem;
        transition: background 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
    }

    .my-form input[type="checkbox"]+label::before,
    .my-form input[type="checkbox"]+label::after {
        content: '';
        position: absolute;
    }

    .my-form input[type="checkbox"]+label::before {
        left: 0;
        top: 6px;
        width: 18px;
        height: 18px;
        border: 2px solid var(--white);
    }

    .my-form input[type="checkbox"]:checked+label::before {
        background: var(--red);
    }

    .my-form input[type="checkbox"]:checked+label::after {
        left: 7px;
        top: 7px;
        width: 6px;
        height: 14px;
        border-bottom: 2px solid var(--white);
        border-right: 2px solid var(--white);
        transform: rotate(45deg);
    }


    /* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
    footer {
        font-size: 1rem;
        text-align: right;
        backface-visibility: hidden;
    }

    footer a {
        text-decoration: none;
    }

    footer span {
        color: var(--red);
    }


    /* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
    @media screen and (min-width: 600px) {
        .my-form .grid {
            display: grid;
            grid-gap: 1.5rem;
        }

        .my-form .grid-2 {
            grid-template-columns: 1fr 1fr;
        }

        .my-form .grid-3 {
            grid-template-columns: auto auto auto;
            align-items: center;
        }

        .my-form .grid>*:not(:last-child) {
            margin-bottom: 0;
        }

        .my-form .required-msg {
            display: block;
        }
    }

    @media screen and (min-width: 541px) {
        .my-form input[type="checkbox"]+label::before {
            top: 50%;
            transform: translateY(-50%);
        }

        .my-form input[type="checkbox"]:checked+label::after {
            top: 3px;
        }
    }
</style>