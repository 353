import Vue from 'vue';
import Router from 'vue-router';
import Homepage from './views/Homepage.vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(Router);
Vue.use(VueScrollTo);
export const router = new Router({
  mode: 'history',
  linkActiveClass: "active",
  routes: [
     {
       path: '/',
       component: Homepage,
       name: "Home",
     },
  ],
    scrollBehavior(to, from) {
        if (to.hash && !to.params.noSlide) {
          VueScrollTo.scrollTo(to.hash, 700);
          return {
            selector: to.hash
          }
        } 
    },
},
);

