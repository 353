<template>
        <div id="skills" class="main_content">
            <div class="skills_container">
                <div class="skills_text_container">
                    <div class="main_text">
                        <div> Skills </div>
                    </div>
                    <div class="story mt-5">
                        For nearly a decade, I've worked with agencies and collaborated with talented people to
                        create web products for business and consumer use.
                        <br>
                        <br>
                        I have worked on more than 300 projects from developing web applications from scratch, to
                        fixing bugs and improving existing systems. I've used a wide variety of technologies and
                        frameworks
                        over the years.
                        <br><br>
                    </div>
                    <button id="contact_button" class="btn btn-outline-warning p-3" >Check out my work!
                    </button>
                </div>
                <div class="skills_list_container">
                    <div class="skill">
                        <div class="skill_label">
                            Front-end <i class="fa-brands fa-html5 ml-3"></i> <i class="fa-brands fa-css3 ml-3"></i> <i
                                class="fa-brands fa-js ml-3"></i> <i class="fa-brands fa-bootstrap ml-3"></i>
                        </div>
                        <div class="progress mt-3">
                            <div class="progress-bar  bg-success"  style="width:80%" >
                            </div>
                        </div>
                    </div>

                    <div class="skill mt-4">
                        <div class="skill_label">
                            Back-end <i class="fa-brands fa-php ml-3"></i> <i class="fa-brands fa-node-js ml-3"></i> <i
                                class="fa-brands fa-laravel ml-3"></i>
                            <i class="fa-brands fa-wordpress ml-3"></i>
                        </div>
                        <div class="progress">
                            <div class="progress-bar  bg-primary"  style="width:74%" >
                            </div>
                        </div>
                    </div>
                    <div class="skill mt-4">
                        <div class="skill_label"> 
                             Vuejs <i class="fa-brands fa-vuejs ml-3"></i>
                        </div>
                         <div class="progress mt-3">
                             <div class="progress-bar  bg-success"  style="width:70%">
                             </div>
                         </div>
                    </div>
                    <div class="skill mt-4">
                        <div class="skill_label">
                             Laravel <i class="fa-brands fa-laravel ml-3"></i>
                        </div>
                        <div class="progress mt-3">
                            <div class="progress-bar  bg-info" style="width:55%" role="progressbar" aria-valuenow="50"
                                aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div class="skill mt-4">
                        <div class="skill_label">
                             NodeJS <i class="fa-brands fa-node ml-3"></i> 
                        </div>
                        <div class="progress mt-3">
                            <div class="progress-bar  bg-warning" style="width:60">
                            </div>
                        </div>
                    </div>
                    <div class="skill mt-4">
                        <div class="skill_label">
                             Wordpress <i class="fa-brands fa-wordpress ml-3"></i>
                        </div>
                        <div class="progress mt-3">
                            <div class="progress-bar  bg-primary"  style="width:60%" >
                            </div>
                        </div>
                    </div>
                    <div class="skill mt-4">
                        <div class="skill_label">
                             MySQL / MongoDB <i class="fa-solid fa-database ml-3"></i>
                        </div>
                        <div class="progress mt-3">
                            <div class="progress-bar  bg-danger"  style="width:80%" >
                            </div>
                        </div>
                    </div>
                    <div class="skill mt-4">
                        <div class="skill_label">
                            Other Frameworks & CMS <i class="fa-brands fa-angular ml-2"></i> <i
                                class="fa-brands fa-drupal ml-2"></i> <i class="fa-brands fa-shopify ml-2"></i> <i
                                class="fa-brands fa-magento ml-2"></i> <i class="fa-solid fa-fire ml-2"
                                title="Codeigniter"></i>
                        </div>
                        <div class="progress mt-3">
                            <div class="progress-bar  bg-warning"  style="width:50%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    export default {
        name: 'Skills',
        props: {},
        data() {
            return {

            };
        },
        methods: {}
    };
</script>
<style scoped>
    .modal-body {
        padding: 0;
    }
</style>