<template>
  <div id="app" :class="darkMode?'dark':''">

    <sidebar-menu 
    :collapsed="false" 
    :hideToggle="true"
    width="150px" 
    :relative="false"
    :class="!sidebarStatus?'closed':''"
    :menu="menu">
      <template v-slot:header>

        <div class="logo_container" v-if="!isCollapsed">
          <img src="/images/logo.webp" alt="logo" width="120px" height="119px">
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-center mb-1 flex-wrap">
          <a href="https://www.linkedin.com/in/hebachi-khalil/" class="p-2" target="_blank">
            <img src="/images/linkedin-icon.svg" width="30px" height="30px" alt="linkedin icon">
          </a>
          <a href="https://www.freelancer.com/u/mondersky" class="p-2"  target="_blank">
            <img src="/images/freelancer-icon.svg" width="30px" height="30px" alt=" freelancer icon">
          </a>
          <a href="https://twitter.com/HebachiKhalil" class="p-2" target="_blank">
            <img src="/images/twitter-icon.svg" width="30px" height="30px" alt="twitter icon">
          </a> 
        </div>
      </template>
    </sidebar-menu>
    <main :class="mainClass">
      <router-view />
      <div class="menu_action_button" @click="toggleSidebar" v-if="isMobile()">
        <i v-if="!sidebarStatus" class="fa-solid fa-bars"></i>
        <i v-if="sidebarStatus" class="fa-solid fa-x"></i>
      </div>
    </main>
  </div>
  </div>
</template>

<script>
  import('./assets/css/custom.css')
  import axios from 'axios';
  import {
    SidebarMenu
  } from 'vue-sidebar-menu'
  import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'


  export default {
    data() {
      return {
        active_hash:"#about",
        sectionObserver: null,
        sidebarStatus: true,
        isCollapsed: false,
        darkMode: true,
        menu: [

          {
            "href": "/#about",
            "title": "About",
            "icon": "fa-solid fa-circle-info",
          },
          {
            "href": "/#skills",
            "title": "Skills",
            "icon": "fa-solid fa-book-skull",
          },
          {
            "href": "/#projects",
            "icon": "fa-solid fa-briefcase",
            "title": "Projects",
          },
          {
            "href": "#reviews",
            "icon": "fa-solid fa-star",
            "title": "Reviews",
          },
          {
            "href": "/#story",
            "icon": "fa-solid fa-book",
            "title": "Story",
          },

          {
            "href": "#contact",
            "title": "Contact",
            "icon": "fa-solid fa-address-book",
          }
        ],
      };
    },
    mounted() {
      this.observeSections();
      this.sidebarStatus=!this.isMobile();
    },
    components: {
      SidebarMenu
    },
    computed: {

      mainClass() {
        let dark = this.darkMode ? 'dark' : ''
        let customClass = "";
        if (this.sidebarStatus) {
          customClass += 'main notCollapsed ' + dark
        } else {
          customClass += 'main isCollapsed ' + dark
        }
        return customClass;
      },
    },
    methods: {
      isMobile() {
         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
             return true
         } else {
             return false
         }
      },
      observeSections() {
        try {
          this.sectionObserver.disconnect()
        } catch (error) {}

        const options = {
          rootMargin: '0px 0px',
          threshold: 0.2
        }
        this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options)
        const sections = document.querySelectorAll('.section')
        sections.forEach(section => {
          this.sectionObserver.observe(section)
        })
      },
      sectionObserverHandler(entries) {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id
            this.active_hash='#'+sectionId;
            break;
          }
        }
       const url = new URL(window.location);
       console.log(url.hash);
       if(url.hash!=this.active_hash){
         this.$router.push({
            name: this.$route.name,
            hash: this.active_hash,
            params: { noSlide: true }
         })
       }
      },
      toggleSidebar(e) {
        // this.isCollapsed = true;
        this.sidebarStatus = !this.sidebarStatus
      },
      toggleMode() {
        this.darkMode = !this.darkMode
      },
      onToggleCollapse(e) {
        this.isCollapsed = e
      },
    }
  };
</script>