<template>
        <div id="projects" class="main_content">
            <div class="main_text">
                <div> Projects </div>
            </div>
            <div class="story mt-5">
                         I've had the pleasure of working on a diverse range of web applications in my career. Here's a list of notable projects I've
                         been involved in:
            </div>
                           
            <div class="project_types mt-5">

                <div v-for="category in categories" @click="category.active=!category.active"
                    :class=
                    "'project_category mr-2 gb-vue-timeline-update__category gb-base-badge gb-base-badge--small gb-base-badge--dark gb-base-badge--filled gb-base-badge--'+category.color+' '+(category.active?'active':'')">
                    <span class="gb-base-badge__focuser"></span>
                    {{category.label}}
                </div>
            </div>
            <!-- <div class="technologies mt-3" @click="technology.active=!technology.active">
               <span v-for="technology in technologies" v-html="technology.icon" class="technology"></span>
            </div> -->
            <div class="my_timeline mt-5" >
            

<vue-timeline-update
 v-for="project in active_projects"
 :key="project.url"
 :title="project.title"
 :description="setTechnologies(project)"
 :category="getCategory(project.cat).label"
 :icon="getCategory(project.cat).icon"
 :color="getCategory(project.cat).color"
 :date="project.date"
 @click:title="openProject(project)"
>
<slot v-if="project.youtube">
    <br>
        <LazyYoutube :src="project.youtube" />
</slot>
</vue-timeline-update>

                        <button @click="limit=projects.length" class="btn btn-outline-warning w-100 p-3 mt-5 show_more_button" 
                            v-if="limit<projects.length">Show more
                         </button>
            </div>             
        </div>
</template>
<script>
    import { LazyYoutube } from "vue-lazytube";
    export default {
        name: 'Projects',
        props: {},
        components: {
        LazyYoutube,
        },
        mounted() {

        },
        data() {
            return {
                limit:9,
                technologies:[
                    {
                        "name":"js",
                        "icon" : '<i class="fa-brands fa-js mr-2"></i>'
                    },
                    {
                        "name":"css",
                        "icon" : '<i class="fa-brands fa-css3 mr-2"></i>'
                    },
                    {
                        "name":"html",
                        "icon" : '<i class="fa-brands fa-html5 mr-2"></i>'
                    },
                    {
                        "name":"php",
                        "icon" : '<i class="fa-brands fa-php mr-2"></i>'
                    },
                    {
                        "name":"mysql",
                        "icon" : '<i class="fa-solid fa-database mr-2"></i>'
                    },
                    {
                        "name":"vue",
                        "icon" : '<i class="fa-brands fa-vuejs mr-2"></i>'
                    },
                    {
                        "name":"react",
                        "icon" : '<i class="fa-brands fa-react mr-2"></i>'
                    },
                    {
                        "name":"laravel",
                        "icon" : '<i class="fa-brands fa-laravel mr-2"></i>'
                    },
                    {
                        "name":"solidity",
                        "icon" : '<i class="fa-brands fa-ethereum mr-2"></i>'
                    },
                    {
                        "name":"chrome",
                        "icon" : '<i class="fa-brands fa-chrome mr-2"></i>'
                    },
                    {
                        "name":"wordpress",
                        "icon" : '<i class="fa-brands fa-wordpress mr-2"></i>'
                    },
                    {
                        "name":"google",
                        "icon" : '<i class="fa-brands fa-google mr-2"></i>'
                    },
                    {
                        "name":"node",
                        "icon" : '<i class="fa-brands fa-node-js mr-2"></i>'
                    },
                    {
                        "name":"facebook",
                        "icon" : '<i class="fa-brands fa-facebook mr-2"></i>'
                    },
                    {
                        "name":"bootstrap",
                        "icon" : '<i class="fa-brands fa-bootstrap mr-2"></i>'
                    },
                ],
                categories:[
                    {
                        name:"startup",
                        label:"Startup",
                        color:"red",
                        active:true,
                        icon:"grade"
                    },
                    {
                        name:"freelance",
                        label:"Freelancing",
                        color:"turquoise",
                        icon:"code",
                        active:true,
                    },
                    {
                        name:"long",
                        label:"Long Term",
                        color:"green",
                        icon:"work",
                        active:true
                    }
                ],
                projects:[
                    {
                        title:"Fast PDR <span class='text-warning'>(in progress)</span>",
                        description:"Development of a Saas platform that brings together factories and vendors, allowing multiple factories to manage and list spare parts inquiries, and vendors to make offers for each spare part. the system automatically chooses the best offers based on multiple criteria (location, price, urgency), creates invoices for both vendors and factories, and confirms and tracks orders.",
                        cat:"freelance",
                        url:'FastPDR',
                        date:new Date('2022-02-08'),
                        technologies:["html","css","js","bootstrap","vue","node","php"]
                    },
                    {
                        title:"NFT Collection Generator <span class='text-warning'>(in progress)</span>",
                        description:"Creation of a visual user-friendly NFT collection Generator that helps artists generate the collection by setting up layers, conditions, rarity conditions, and metadata in a very convenient way, then deploying it to a blockchain.",
                        cat:"startup",
                        url:'nft-collection-generator',
                        date:new Date('2022-02-08'),
                        technologies:["html","css","js","bootstrap","react","solidity","node"]
                    },
                    {
                        title:"NFT Collection <span class='text-danger'>(NDA Project)</span> <span class='text-warning'>(in progress)</span>",
                        description:"Creation of an NFT collection with a group of artists, developers, community managers & marketers.<br> <span class='badge badge-warning mr-1'>idea conception</span><span class='badge badge-warning mr-1'>team leading</span><span class='badge badge-warning mr-1'>website development</span><span class='badge badge-warning mr-1'>nft contract development</span><span class='badge badge-warning mr-1'>videos creation</span><span class='badge badge-warning mr-1'>SMM & marketing</span><br> <a href='#contact'>Contact me to know more about the project</a>",
                        cat:"startup",
                        url:'nft-collection',
                        date:new Date('2021-05-08'),
                        technologies:["html","css","js","react","solidity","node"]
                    },
                    {
                        title:"Rsoutlet ",
                        description:"Development of a tailored data mining chrome extension & WordPress wooCommerce products importer built to optimize the workflow of a special online clothing store owner.",
                        cat:"freelance",
                        url:'RSoutlet',
                        date:new Date('2021-01-08'),
                        technologies:["html","css","js","php","mysql","chrome","wordpress"]
                    },
                    {
                        title:"Aviadmin <span class='text-success'>(2020-2022)</span>",
                        description:"Aviadmin is an advanced aviation administration portal software. Featuring many features to ease the everyday administration of the already extremely complex environment of aviation and at the same time also function as a portal for pilots, student pilots and aviation personnel with many operational features to secure the quality of aviation procedures. Platform can be accessed on a cloud version or a licensed local server version. <br> <span class='badge badge-warning mr-1'>Re-designing</span> <span class='badge badge-warning mr-1'>Bug Fixing</span> <span class='badge badge-warning mr-1'>new functionalities development</span>",
                        cat:"long",
                        url:'phoenixflight',
                        date:new Date('2020-02-08'),
                        technologies:["html","css","js","mysql","php"]
                    },
                    {
                        title:"Sociallify",
                        description:" Sociallify is a Saas platform that enables users to generate leads, detect issues related to their website (Speed,SEO ,Facebook Pixel...), generate dynamic reports, manage leads, setup dynamic email & messages templates and outreach potential clients via email or messenger.<br> <span class='badge badge-warning mr-1'>Idea conception</span><span class='badge badge-warning mr-1'>development</span>",
                        cat:"startup",
                        url:'sociallify',
                        youtube:"https://www.youtube.com/watch?v=K6ex27MRrzw",
                        date:new Date('2020-04-08'), 
                        technologies:["html","css","js","mysql","php","laravel","vue","node","facebook","google"]
                    },
                    {
                        title:"Mailgard <span class='text-success'>(Black & Yellow Ltd)</span>",
                        description:"Creation and maintenance of an email protection service platform. MailGuard is a smart way of protecting people's inboxes from scams. The platform enters as a middleware between the sender and the user’s inbox.",
                        cat:"long",
                        url:'mailgard',
                        date:new Date('2020-01-01'),
                        technologies:["html","css","js","mysql","php"]
                    },
                    {
                        title:"CanBeFaster",
                        description:"Canbefaster is a 2 in 1 cloud based App that includes a Lead Finding Tool which helps you to find clients & contact them"
                        +" and a universal plugin that enhances the speed of any PHP/Apache based website. <br><span class='badge badge-warning mr-1'>idea conception</span><span class='badge badge-warning mr-1'>team leading</span><span class='badge badge-warning mr-1'>development</span><span class='badge badge-warning mr-1'>marketing</span><span class='badge badge-warning mr-1'>sales funnels & sales pages conception</span>",
                        cat:"startup",
                        youtube:"https://www.youtube.com/watch?v=0ug8fl7kvPI&t",
                        url:'canbefaster',
                        date:new Date('2019-10-08'),
                        technologies:["html","css","js","mysql","php","laravel","vue","node","facebook","google"]
                    },

                    {
                        title:"The Service Desk <span class='text-success'>(Black & Yellow Ltd)</span>",
                        description:"Creation & maintenance of a tailored ticketing system platform based on the specific needs of 'Black & Yellow Ltd', a British IT company. ",
                        cat:"long",
                        url:'the-service-desk',
                        date:new Date('2018-01-08'),
                        technologies:["html","css","js","mysql","php","node"]
                    },
                    {
                        title:"Pro-archive <span class='text-danger'>(NDA Project)</span>",
                        description:"Development of a web app used by an archiving company.",
                        cat:"freelance",
                        url:'pro-archive',
                        date:new Date('2017-05-08'),
                        technologies:["html","css","js","mysql","php","node"]
                    },
                    {
                        title:"Cameratique",
                        description:"Creation of multiple bots capable of automating repetitive manual tasks such as products data formatting and data import from spreadsheets",
                        cat:"freelance",
                        url:'',
                        date:new Date('2017-05-08'),
                        technologies:["html","css","js","mysql","php","node"]
                    },
                    {
                        title:"InstaQuotes",
                        description:"Scrapped brainy quotes, created a quote to image tool using nodejs, created Instaquotes website using codeignitor, setted-up an SEO strategy, created satellite websites : lovequotesforher, lovequotesforhim, sadlovequotes) backlinked the satellite websites to instaquotes.",
                        cat:"startup",
                        url:'InstaQuotes',
                        date:new Date('2017-05-08'),
                        technologies:["html","css","js","mysql","php","node"]
                    },

                    {
                        title:"Livrily",
                        description:"A web solution in the form of a competition platform for distributors, guaranteeing low prices for businesses (supermarkets, grocery stores, etc.) and more opportunities for deliverers/distributors",
                        cat:"startup",
                        url:'livrily',
                        technologies:["js","html","css"],
                        date:new Date('2016-11-27'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                    {
                        title:"Recovery truck software",
                        description:"A recovery truck saas made for those who run into issues with their vehicles, so that they can quickly and easily find a recovery truck near them and get the help they need",
                        cat:"freelance",
                        date:new Date('2015-09-06'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                    {
                        title:"Ta7t 3inek",
                        description:"TA7T 3INEK is a premium geolocation service that uses multiple kinds of trackers to let parents keep a constant eye on their childrens, the service comes with a platform, multiple tools like specify allowed regions, alerts and much more.",
                        cat:"startup",
                        date:new Date('2014-09-06'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                    {
                        title:"Phone Down",
                        description:"Development of a web application that encourages people to spend less time on their smartphones. The application offers a smart way to count the time spent without a phone and reward users accordingly.",
                        cat:"freelance",
                        date:new Date('2014-04-06'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                    {
                        title:"Sarai",
                        description:"Creation of a web based solution that consists of managing a shopping center renting states in order to let the owner know in a simple view what premise he owns is free/occupied/rented and how long until the next payment",
                        cat:"long",
                        date:new Date('2013-04-06'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                    {
                        title:"A9dili",
                        description:"Having noticed that people are annoyed by wasting a lot of time on daily shopping, “A9DILI” is to provide a simple home shopping delivery service, Thus, anyone wishing to save time, the elderly and people with reduced mobility will find in our services a real SOLUTION to their shopping problems",
                        cat:"startup",
                        date:new Date('2013-04-06'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                    {
                        title:"FASTLINER",
                        description:"Creation of a web based solution that consists of managing all the aspects of a large truck transport company",
                        youtube:"https://www.youtube.com/watch?v=rvUthy0BXdM",
                        cat:"long",
                        date:new Date('2013-04-06'),
                        technologies:["html","css","js","mysql","php","google"]
                    },
                ]
            };
        },
        methods: {
            openProject(project){
            },
            getTechnology(techSearch){
                return this.technologies.filter(function (technology) {
                        return technology.name == techSearch
                })[0]
            },
            getCategory(catsearch){
                return this.categories.filter(function (category) {
                        return category.name == catsearch
                })[0]
            },
            setTechnologies(project){
                let technologies_html="";
                    for (let index = 0; index < project.technologies.length; index++) {
                        const technology = project.technologies[index];
                        technologies_html+=this.getTechnology(technology).icon
                    }
                technologies_html+="</br>"
                return technologies_html+project.description;
            }
        },
        computed:{
            active_projects(){
                let that=this;
                return that.projects.filter(function (project) {
                    return that.categories.some(function(category){
                        return (category.active&&category.name==project.cat)
                    })
                }).slice(0,this.limit)
            }
        }
    };
</script>
<style scoped>
    .modal-body {
        padding: 0;
    }
</style>