<template>
    <div class="container-fluid position-relative">
        <Landing class="section"></Landing>
        <Skills class="section"></Skills>
        <Projects class="section"></Projects>
        <Reviews class="section"></Reviews>
        <Story class="section"></Story>
        <Contact class="section"></Contact>

    </div>
</template>
<script>
    import Landing from "@/components/Landing"
    import Skills from "@/components/Skills"
    import Projects from "@/components/Projects"
    import Story from "@/components/Story"
    import Reviews from "@/components/Reviews"
    import Contact from "@/components/Contact"
    export default {
        name: 'Homepage',
        mounted() {},
        components: {
            Landing,
            Skills,
            Projects,
            Story,
            Reviews,
            Contact,
        },
        computed: {},
        watch: {
            '$route.params': {
                handler(value) {
                },
                immediate: true,
            },
        },
        data() {
            return {}
        },
        methods: {
            test(e){
                console.log(e);
            }
        }
    }
</script>
<style scoped>

</style>