<template>
    <div id="reviews" class="main_content">
                    <div class="main_text">
                        <!-- <div>Reviews & Testimonials</div> -->
                        <div>Reviews</div>
                    </div>
                <div class="reviews_container text-white mt-5 p-0 m-0">
                    <div class="review">
                         <div class="rating mb-2">
                             <i class="fa-solid fa-star"></i>
                             <i class="fa-solid fa-star"></i>
                             <i class="fa-solid fa-star"></i>
                             <i class="fa-solid fa-star"></i>
                             <i class="fa-solid fa-star"></i>
                         </div>
                            <div class="text">Excellent. Delivered perfect solution, ensured requirements were well
                                understood, asked good questions, excellent technical skills. One of the best
                                Freelancers I have had the pleasure to work with.
                            </div>
                               
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/gb.webp" alt=""  class="mr-2">
                                <span>
                                        Mr Steven G M
                                </span>
                                <a class="ml-2" href="https://www.freelancer.com/projects/Javascript/JavaScript-List-Builder/reviews" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">Very honest guy. Perfect execution on the vision I had.
                                A big, big bonus for actually giving a demo -before- hiring.

                                Will definitely come back. Recommended!
                                If you want a job done quickly with no hassle - this is your guy.</div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/no.webp" alt="" class="mr-2">
                                <span>
                                        Adam A.
                                </span>
                                <a class="ml-2" href="https://www.freelancer.com/projects/php/Automatic-Islamic-Prayer-Times-Wordpress/reviews" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">One of the best freelancers I've hired ever :) He provided a demo before
                                accepting the project, the communication was perfect and he finished everything
                                superfast and with perfect quality. Cannot ask for more! Recommended 1000%</div>
                            <hr>

                            <div class="client">
                                <img src="images/reviews/countries/my.webp" alt=""  class="mr-2">
                                <span>
                                        Manu Andersen.
                                </span>
                                <a class="ml-2" href="https://www.freelancer.com/projects/php/Interactive-Map-edits/reviews" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                                
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">
                                Awesome Freelancer! His expertise is top drawer and he is totally focused on the
                                customer being more than satisfied. Very happy with hiring him for all 3 phases of this
                                project, and will hire again. Highest recommendation!
                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/ca.webp" alt="" class="mr-2">
                                <span>
                                        Talbot S.
                                </span>
                                <a class="ml-2" href="https://www.freelancer.com/projects/php/Project-for-Brahim/reviews" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">
                               He is great freelancer. So, fast even though the task was a bit complicated. I requested
                               him to edit and fix some flaw in imageLightBox jQuery plugin. He did it so fast. This guy
                               also so responsive. I highly recommend him for anyone. You will not regret hiring him.
                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/us.webp" alt="" class="mr-2">
                                <span>
                                        Saleh A.
                                </span>
                                <a class="ml-2" href="https://www.freelancer.com/projects/jquery/Fix-imageLightBox-jQuery-plugin/reviews" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">
                              After working with multiple developers both offline and online I can truly say that Mondersky
                              offers an exceptional service that is priced very competitively. His great communication,
                              hard work and skills allowed us to complete a challenging development project within a
                              very good time frame. Highly recommended for all types of development work!

                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/za.webp" alt="" class="mr-2">
                                <span>
                                        Marcus P.
                                </span>
                                <a class="ml-2"
                                    href="https://www.freelancer.com/projects/php/Integrate-Wordpress-website-with-API"
                                    target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">
                             Fix a simple Vuejs html5 + javascript Game: <br><br>
                             Great Freelancer, knows how to get things done, quick and professional!
                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/iq.webp" alt="" class="mr-2">
                                <span>
                                        MYZONE.
                                </span>
                                <a class="ml-2"
                                    href="https://www.freelancer.com/projects/javascript/Fix-simple-Vuejs-html-javascript-33018764/reviews"
                                    target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                    <div class=" review">
                        <div class="rating mb-2">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                        </div>
                            <div class="text">
                             A detailed, honest, decent and well written Profile, skill set, portfolio and "pre-sales"
                             communication + free work session related to our project gave us the confidence to hire
                             Mondersky. During our project he communicated a couple times a day and informed us about
                             the progress immediately with great communication skills. After feedback and change
                             requirements he implemented the solution skillful in no-time. Mondersky exceeded our
                             expectations in many ways. A fantastic first experience on Freelancer for us - a great guy
                             and highly recommended!!! Will hire him again .

                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/countries/de.webp" alt="" class="mr-2">
                                <span>
                                        Uwe K
                                </span>
                                <a class="ml-2"
                                    href="https://www.freelancer.com/projects/Wordpress/Datepicker-Custom-Form-for-Wordpress/reviews"
                                    target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                </div>
                <!-- <div class="reviews_container text-white mt-5 p-0 m-0">
                    <div class="review testimonial">
                            <h3 class="title text-warning"><b>Black & Yellow LTD</b></h3>
                            <div class="text">
                                waiting for Richard to send me the testimonial
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis velit voluptas esse consectetur odit. Rem, dolore aperiam, enim harum et obcaecati aliquam quia sunt perspiciatis tempore eos, illum id ratione.
                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/richard.webp" alt="" width="50px" class="mr-2">
                                <span>
                                       Richard Harvey.
                                </span>
                                <a class="ml-2" href="https://www.freelancer.com/projects/php/Project-for-Brahim/reviews" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                     <div class="review testimonial">
                            <h3 class="title text-warning"><b>Aviadmin</b></h3>
                            <div class="text">
                                waiting for Henrik to send me his testimonial
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro mollitia cumque magni quas impedit pariatur voluptatibus, blanditiis totam amet praesentium fugit, officia fuga rerum fugiat provident? Quia eaque velit illum!
                            </div>
                            <hr>
                            <div class="client">
                                <img src="images/reviews/henrik.webp" alt="" width="50px" class="mr-2">
                                <span>
                                        Henrik Krag.
                                </span>
                                <a class="ml-2" href="https://www.linkedin.com/in/henrik-krag-9ab5b65/" target="_blank">
                                    <i class="fa-solid fa-link"></i>
                                </a>
                            </div>
                    </div>
                </div> -->
    </div>
</template>
<script>
    export default {
        name: 'Reviews',
        props: {},
        data() {
            return {

            };
        },
        methods: {}
    };
</script>