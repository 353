<template>
        <div id="story" class="main_content">
            <div class="skills_container">
                <div class="skills_text_container">
                    <div class="main_text">
                        <div> Me, Myself & Mo </div>
                    </div>
                    <div class="story mt-5" >
                        I am a solution-oriented self-taught full stack web developer who’s passionate about developing
                        intuitive and easy-to-use apps that can impact people's lives in a positive manner. I
                        enjoy problem solving
                        for better processes and outcomes. I look for solutions that get results, with an
                        entrepreneurial mind set.
                        <br> 
                        <br> 
                        IT is not only my professional background, it is
                        also and above
                        all a passion that has grown since I first got a PC in 1996.
                         The discovery of (Prince of Persia) in 1997 had a big impact on me, I loved the way its
                         creators used conditional logic to create an immersive experience for players. I thought that
                         this will revolutionize how we do things in the future; there are so many
                         repetitive tasks around us that could be automated using conditional logic.
                        <br>
                        <br>
                        My professional career started at the Hackathon Annaba <b>2013</b>, where I and my friend Monder
                        participated in with the SEO BANK project, while programming, an investor came in and asked if we would be interested in building his
                        company website, we said yes, and the journey started from that point. <br>
                       Monder had a good freelancer.com profile, I had an office, and we're both developers with
                       complementary skills, so it made sense for both of us to work together. <br>
                       Since then, we have:
                        <ul class="mt-2">
                            <li class="mt-1">
                                 Created <a target="_blank" href="https://mawaki3.com">Mawaki3</a> a web development
                                 company where we trained & managed junior developers.
                            </li>
                            <li class="mt-1">
                                Worked with clients from around the world on nearly <b>300 projects</b> on
                                freelancer.com. (you can check our profile <a href="https://www.freelancer.com/u/mondersky"
                                    target="_blank">mondersky</a>)
                            </li>
                            <li class="mt-1">
                                Launched multiple startups and SAAS products and most lately we're working on
                                an NFT collection project.
                            </li>
                        </ul>
                        <br><br>

                    </div>
                    <!-- <div class="mt-5 read_more" @click="showingFullText = !showingFullText">
                        <b class="text-warning "> Read {{ showingFullText ? "Less" : "More" }}</b>
                    </div> -->
                </div>
                <div class="skills_list_container">
                    <div>
                         <VueSlickCarousel :settings="carouselSettings">
                             <div class="img_container">
                                 <img width="100%" src="/images/slider/1.webp">
                             </div>
                             <div class="img_container">
                                 <img width="100%" src="/images/slider/2.webp">
                             </div>
                             <div class="img_container">
                                 <img width="100%" src="/images/slider/3.webp">
                             </div>
                             <div class="img_container">
                                 <img width="100%" src="/images/slider/4.webp">
                             </div>
                         </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
        import VueSlickCarousel from 'vue-slick-carousel'
        import 'vue-slick-carousel/dist/vue-slick-carousel.css'
        import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    export default {
        name: 'Skills',
        props: {},
        data() {
            return {
                    carouselSettings: {
                        "dots": true,
                        "infinite": true,
                        "initialSlide": 2,
                        "speed": 500,
                        "slidesToShow": 3,
                        "slidesToScroll": 1,
                        "swipeToSlide": true
                    },
                showingFullText:false,
                    body:
                    "I’m a full stack web developer. IT is not only my professional background, it is also and above all a passion that has grown since I first got a PC in 1996. <br> <br>"
                    +" When I first got a PC, I immediately wanted to see what was inside. As soon as it was late at night, and my parents were asleep, I got a screwdriver and took it all apart. I was fascinated by how it worked—well, at least for the first five minutes. Then I couldn’t get it back together again"
                    +"<br> <br> Since then, I’ve continued to tinker with anything related to electronics but by playing video games like (Prince of persia 1989) I loved the logic behind it, I understood that it’s conditional “if this then do this, else do this…” I understand that this will certainly change the world there’s so many repetitive tasks we’re doing that we’ll not do anymore because everything can be programmed like in video games. <br> <br> Later on in high school, I became the guy in the classroom that knows how to repair a printer, or use word, excel, install cracked video games in pc’s, download music and movies from the internet, install and use multiple kinds of software, without knowing, electronics & the internet were absorbing me, Then came the university, and obviously I chose “math and computer science” as a speciality <br> <br> My professional career started in the Hackathon Annaba 2013, me and my friend monder participated in together with the SEO BANK Project, while programming, an investor came in and told us hey guys, you’re interested in making my company website? Well, we said YES, and the journey started from that point. Monder had a good freelancer.com profile, me on the other hand had an office, & we're both developers and our skills complement each other, so it made sense for us to work toguether, since then, we accomplished more than 300 projects for foreign and local clients. Additionally, we launched multiple startups and SAAS products and most lately we're working on an NFT collection."
            };
        },
        components:{
            VueSlickCarousel
        },
        methods: {},
          computed: {
          formattedBody() {
          if (this.showingFullText) {
          return this.body;
          }

          return `${this.body.slice(0, 600).trim()}...`;
          }
          }
    };
</script>
<style scoped>
    .img_container{
        height: 400px;
        width: 400px;
    }
    .img_container img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        /* max-width: 300px; */
    }
</style>